<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2021-04-06 15:41:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="addCity box-create">
        <p style=""></p>
        <div class="box-title">{{$t('battery.data-add')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="20">
                <el-col :span="10" :offset="1">
                    <!-- <el-divider content-position="center">{{$t('project.regular-data')}}</el-divider> -->
                    <el-form-item :label="$t('project.battery-type')"  prop="name" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.name" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Nominal-voltage') + '(V)'"  prop="nominalVolatage" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.nominalVolatage" :placeholder="$t('common.info.pl-insert-content')" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Operating-voltage-range') + '(V)'"  required>
                        <el-col :span="11">
                            <el-form-item prop="operatingVoltageFrom" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                <el-input v-model="form.operatingVoltageFrom" :placeholder="$t('common.info.pl-insert-content')"  type="number" v-number ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="operatingVoltageTo" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                <el-input v-model="form.operatingVoltageTo" :placeholder="$t('common.info.pl-insert-content')" type="number" v-number></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Total-energy') + '(kWh)'" prop="totalEnergy" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.totalEnergy" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Usable-energy') + '(kWh)'" prop="usableEnergy" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.usableEnergy" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Rated-capacity') + '(Ah)'" prop="ratedCapacity" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.ratedCapacity" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Nominal-power') + '(kW)'" prop="nominalPower" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.nominalPower" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Max.-power') + '(kW)'" prop="maxPower" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.maxPower" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>


                    <!-- <el-divider content-position="center">{{$t('project.data.dailyRadiation')}}</el-divider> -->
                    <el-form-item :label="$t('battery.data.Battery-to-Inverter')"  >
                        <el-input v-model="form.batteryToInverter"  :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Battery-to-battery/BMS')"  >
                        <el-input v-model="form.batteryTobattery"  :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <!-- <el-form-item :label="$t('battery.data.Master-control-capacity-indicator')" prop="regionID" :rules="[{ required: true, message: $t('remind.not-null')}]" >
                        <el-select v-model="form.regionID" @change="regionChange">
                            <el-option value="20%"> </el-option>
                            <el-option value="40%"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Master-control-LED-indicator')" prop="regionID" :rules="[{ required: true, message: $t('remind.not-null')}]" >
                        <el-select v-model="form.regionID" @change="regionChange">
                            <el-option value="LED"> </el-option>
                            <el-option value="40%"> </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item :label="$t('battery.data.System-switch')" prop="systemSwitch" :rules="[{ required: false, message: $t('remind.not-null')}]" >
                       
                        <el-checkbox v-model="form.systemSwitch" ></el-checkbox>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Width') + '(mm)'" prop="width" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.width" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Height') + '(mm)'" prop="height" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.height" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Depth') + '(mm)'" prop="depth" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.depth" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Net-weight') + '(kg)'" prop="netWeight" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.netWeight" :placeholder="$t('common.info.pl-insert-content')" type="number" v-number></el-input>
                    </el-form-item>
                    
                    
                    
                    
                </el-col>
                <el-col :span="10" :offset="2">
                    <!-- <el-divider content-position="center" style="margin-top:62px">{{$t('project.data.energy-data')}}</el-divider> -->
                   <el-form-item :label="$t('battery.data.Recommend-charge/discharge-current') + '(A)'" prop="recommendCurrent" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.recommendCurrent" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Max.-charge/discharge-current') + '(A)'" prop="maxCurrent" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.maxCurrent" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Battery-roundtrip-efficiency')" prop="roundtripEfficiency" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.roundtripEfficiency"  :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Cycle-life')" prop="cycleLife" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.cycleLife"  :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Expected-life-time/Warranty')" >
                        <el-input v-model="form.expectedLife"  :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Available-charge/discharge-temerature-range') + '(℃)'" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-col :span="11">
                            <el-form-item prop="availableTempFrom" :rules="[{ required: false, message: $t('remind.not-null')}]">
                                <el-input v-model="form.availableTempFrom"  type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="availableTempTo" :rules="[{ required: false, message: $t('remind.not-null')}]">
                                <el-input v-model="form.availableTempTo" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Storage-temperature')+'(℃)'"  >
                        <el-col :span="11">
                            <el-form-item prop="storageTempFrom" :rules="[{ required: false, message: $t('remind.not-null')}]">
                                <el-input v-model="form.storageTempFrom"  type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="storageTempTo" :rules="[{ required: false, message: $t('remind.not-null')}]">
                                <el-input v-model="form.storageTempTo" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Relative-huminity') + '(%)'" >
                        <el-col :span="11">
                            <el-form-item prop="relativeHuminityFrom" :rules="[{ required: false, message: $t('remind.not-null')}]">
                                <el-input v-model="form.relativeHuminityFrom" v-number type="number" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="relativeHuminityTo" :rules="[{ required: false, message: $t('remind.not-null')}]">
                                <el-input v-model="form.relativeHuminityTo" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Altitude')" >
                        <el-input v-model="form.altitude" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('battery.data.Degreee-of-protection')"  >
                        <el-input v-model="form.degreeofProtection" type="number" v-number :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </el-form-item>
                    
                         
                    <el-form-item :label="$t('project.bat.pic')" prop="uploadFile" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <baseFile v-model='form.uploadFile' @change='returnImgUrl' :name='form.pictureUrl || ""'></baseFile>
                        <img :src="imgBaseUrl" alt="" width="150px">
                    </el-form-item>
                    
                
                </el-col>
       
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveForm('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminBattery'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        return {
            form:{
            },
            batteryId: this.$route.query.id,
            regions:[],
            countrys:[],
            defaultGridIDs:[5,6,13],
            imgBaseUrl:''
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.batteryId){
            this.tbatteryGetById()
        }

    },
    methods: {
        // inputLimit(e) {
        //     console.log(e);
        //     let key = e.key
        //     if (key === 'e') {
        //         e.returnValue = false
        //         return false
        //     }else if (key === 'E') {
        //         e.returnValue = false
        //         return false
        //     }
        //     return true
        // },
        changeNomPowerform(){
            let nomPowerTo = this.form.nomPowerTo && parseInt(this.form.nomPowerTo)
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            this.form.nomPowerFrom = nomPowerFrom > nomPowerTo ? nomPowerTo - 1 :nomPowerFrom
        },
        changeNomPowerTo(){
            let nomPowerTo = this.form.nomPowerTo? parseInt(this.form.nomPowerTo) : ''
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            if((nomPowerTo <= nomPowerFrom) && nomPowerFrom && nomPowerTo){
                this.$message({
                    message: this.$t('nomPowerFrom.big'),
                    type: 'warning'
                });
                this.form.nomPowerTo = parseInt(nomPowerFrom) + 1
                nomPowerTo = parseInt(nomPowerFrom) + 1
            }
            if(nomPowerTo >150 || nomPowerTo  == 150){
                // console.log(this.form.nomPowerTo,'?');
                this.form.nomPowerTo = 149
            }else if(nomPowerTo <=100){
                this.form.nomPowerTo = 100
            }
        },
        /*获取详情数据*/
        async tbatteryGetById(){
            let res = await this.API.tbatteryGetById({
                battery:this.batteryId,
                id:this.batteryId,
            })
            this.form = res
            this.form.uploadFile = ''
            this.systemTuserGetLogo(res.pictureUrl)
        },
        
        /*保存提交*/
        saveForm(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if($this.batteryId){
                        $this.update($this.form)
                    }else {
                        $this.save($this.form)
                    }
                } else {
                    return false;
                }
            });
        },

        /*更新*/
        async update(data){
            let res = await this.API.tbatteryUpdate(data)
            this.showMessage(res)

        },

        /*创建*/
        async save(data){
            let res = await this.API.tbatterySave(data)
            this.showMessage(res)
        },

        showMessage(res){
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$router.push({path: '/adminBattery'});
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },

        returnImgUrl(){
            let that = this
            if(this.form.uploadFile){
                var reader=new FileReader();
                reader.readAsDataURL(this.form.uploadFile);
                reader.onload=function (e) {
                     that.imgBaseUrl=e.target.result;
                }
            }
        },
        async systemTuserGetLogo(url){
            let res = await this.API.systemTuserGetLogo({
                fileName:url
            })
            if(res){
                this.imgBaseUrl ='data:image/gif;base64,' + res
            }
        },


    },
};
</script>

<style lang="less">
.box-create{
    .box-title{
        margin-bottom: 10px;
        font-weight: 700;
    }
    .el-form{
        .el-divider{
            white-space: nowrap;
        }
        .el-input-group__append, .el-input-group__prepend{
            padding: 0;
            width: 50px;
            text-align: center;
            font-size: 12px;
        }
        .el-input-group+.el-input-group{
            margin-left:10px;
        }
        .el-input-group.inline{
            width:112px;
        }
        .el-col-11,.el-col-2{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .el-col-2{
            text-align: center;
        }
    }
}
    
</style>
